<template>
  <div class="index-vue">
    <!-- 侧边栏 -->
    <aside :class="asideClassName">
      <!-- logo -->
      <div class="logo-c" @click="logoBtn">
        <img
          class="logo-c-img"
          src="../assets/logoActive.png"
          alt=""
          srcset=""
          v-show="isShowAsideTitle"
        />
      </div>
      <!-- 菜单栏 -->
      <Menu
        class="menu"
        ref="asideMenu"
        theme="dark"
        width="100%"
        @on-select="selectMenuCallback"
        accordion
        :open-names="openMenus"
        :active-name="currentPage"
        @on-open-change="menuChange"
      >
        <!-- 动态菜单 -->
        <div v-for="(item, index) in menuItems" :key="index">
          <Submenu
            :class="isShowAsideTitle ? '' : 'shrink'"
            v-if="item.children"
            :name="index"
          >
            <template slot="title">
              <!-- <Icon :size="item.size" :type="item.type" /> -->
              <i :size="item.size" :class="item.type"></i>
              <span v-show="isShowAsideTitle">{{ item.text }}</span>
            </template>
            <div v-for="(subItem, i) in item.children" :key="index + i">
              <Submenu
                :class="isShowAsideTitle ? '' : 'shrink'"
                v-if="subItem.children"
                :name="index + '-' + i"
              >
                <template slot="title">
                  <i :size="subItem.size" :class="subItem.type"></i>
                  <span v-show="isShowAsideTitle">{{ subItem.text }}</span>
                </template>
                <template v-for="(threeItem, k) in subItem.children">
                  <MenuItem
                    :class="isShowAsideTitle ? '' : 'shrink'"
                    class="menu-level-3"
                    :name="threeItem.name"
                    :key="index + i + k"
                  >
                    <template v-if="!threeItem.hidden">
                      <i :size="threeItem.size" :class="threeItem.type"></i>
                      <span v-show="isShowAsideTitle">{{
                        threeItem.text
                      }}</span>
                    </template>
                  </MenuItem>
                </template>
              </Submenu>
              <template v-else-if="!subItem.hidden">
                <a
                  :href="subItem.url"
                  v-if="subItem.isExternal"
                  target="_blank"
                  class="external"
                >
                  <MenuItem
                    :class="isShowAsideTitle ? '' : 'shrink'"
                    :name="'external-link-' + index + '-' + i"
                  >
                    <i :size="subItem.size" :class="subItem.type"></i>
                    <span v-show="isShowAsideTitle">{{ subItem.text }}</span>
                  </MenuItem>
                </a>
                <MenuItem
                  v-else
                  :class="isShowAsideTitle ? '' : 'shrink'"
                  :name="subItem.name"
                >
                  <i :size="subItem.size" :class="subItem.type"></i>
                  <span v-show="isShowAsideTitle">{{ subItem.text }}</span>
                </MenuItem>
              </template>
            </div>
          </Submenu>
          <template v-else-if="!item.hidden">
            <a
              :href="item.url"
              v-if="item.isExternal"
              target="_blank"
              class="external"
            >
              <MenuItem
                :class="isShowAsideTitle ? '' : 'shrink'"
                :name="'external-link-' + index"
              >
                <i :size="item.size" :class="item.type"></i>
                <span v-show="isShowAsideTitle">{{ item.text }}</span>
              </MenuItem>
            </a>
            <MenuItem
              v-else
              :class="isShowAsideTitle ? '' : 'shrink'"
              :name="item.name"
            >
              <i :size="item.size" :class="item.type"></i>
              <span v-show="isShowAsideTitle">{{ item.text }}</span>
            </MenuItem>
          </template>
        </div>
      </Menu>
    </aside>

    <!-- 右侧部分 -->
    <section class="sec-right">
      <!-- 头部 -->
      <div class="top-c">
        <header>
          <div class="h-left">
            <div class="pointer" @click="isShrinkAside">
              <Icon
                type="ios-apps"
                style="color: #d8d8d8; font-size: 20px; margin-left: 20px"
              />
            </div>
            <!-- 面包屑功能 -->
            <p class="crumbs">乡米网合伙人后台管理</p>
          </div>
          <div class="h-right">
            <!--申请合伙人-->
            <div
              class="partner-c"
              v-if="WalletInfoObj.collaborateStatus != 1"
              @click="partnerStop"
            >
              <img src="../assets/partner-c.png" alt="" />
              申请合伙人
            </div>
            <!-- 消息 -->
            <div class="notice-c" @click="info" title="查看新消息">
              <div
                :class="{
                  newMsg: this.$store.state.isRead == 0 ? true : false,
                }"
              ></div>
              <Icon type="ios-notifications-outline" />
            </div>
            <!-- 用户头像 -->
            <div class="user-img-c">
              <img :src="indexObj.avatar" />
            </div>
            <!-- 下拉菜单 -->
            <el-dropdown trigger="click" @click="handleClick">
              <div
                class="designationName"
                style="
                  cursor: pointer;
                  font-size: 14px;
                  font-family: DINAlternate-Bold, DINAlternate;
                  font-weight: bold;
                  color: #444444;
                "
              >
                {{ indexObj.nickname
                }}<i
                  style="margin-left: 8px"
                  class="el-icon-arrow-down el-icon--right"
                ></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="dropdown-div">
                    <div class="dropdown-div-top">
                      <img
                        class="dropdown-div-top-img"
                        :src="indexObj.avatar"
                      />
                      <div class="dropdown-div-top-name">
                        {{ indexObj.nickname }}
                      </div>
                    </div>

                    <div class="dropdown-div-center1">
                      <div class="center1-left"></div>
                      <div class="center1-right">
                        {{
                          WalletInfoObj.collaborateStatus == 1
                            ? WalletInfoObj.collaborateLevel == 1
                              ? '初级合伙人'
                              : WalletInfoObj.collaborateLevel == 2
                              ? '精英合伙人'
                              : WalletInfoObj.collaborateLevel == 3
                              ? '事业合伙人'
                              : ''
                            : '普通用户'
                        }}
                      </div>
                    </div>

                    <div class="dropdown-div-center1" style="margin-top: 5px">
                      <div class="center1-left"></div>
                      <div
                        class="center2-right"
                        v-if="WalletInfoObj.collaborateStatus == 1"
                      >
                        收益权重比为{{ WalletInfoObj.rerate * 100 }}%
                      </div>
                    </div>

                    <div class="dropdown-div-lint"></div>

                    <div class="dropdown-div-bottom">
                      <div class="bottom-list" @click="partnerStop">
                        <div
                          class="left"
                          :style="{
                            color:
                              WalletInfoObj.collaborateStatus == -2
                                ? '#E8E8E8'
                                : '',
                          }"
                        >
                          申请合伙人
                        </div>
                        <div class="right">
                          <div
                            class="right-title"
                            v-if="WalletInfoObj.collaborateStatus == -2"
                          >
                            审核中
                          </div>

                          <i
                            class="el-icon-arrow-right"
                            style="width: 8px; height: 8px"
                          ></i>
                        </div>
                      </div>

                      <div class="bottom-list" @click="recordStop">
                        <div
                          class="left"
                          :style="{
                            color:
                              WalletInfoObj.collaborateStatus == -2
                                ? '#E8E8E8'
                                : '',
                          }"
                        >
                          保证金缴纳记录
                        </div>
                        <div class="right">
                          <i
                            class="el-icon-arrow-right"
                            style="width: 8px; height: 8px"
                          ></i>
                        </div>
                      </div>
                      <!--
                      <div
                        class="bottom-list"
                        v-if="WalletInfoObj.collaborateStatus == 1"
                        @click="relieveStop"
                      >
                        <div class="left">解除合伙人协议</div>
                        <div class="right">
                          <i
                            class="el-icon-arrow-right"
                            style="width: 8px; height: 8px"
                          ></i>
                        </div>
                      </div> -->

                      <!-- <div
                        class="bottom-list"
                        @click="reimburseStop"
                        v-if="WalletInfoObj.collaborateStatus == 1"
                      >
                        <div class="left">申请退还保证金</div>
                        <div class="right">
                          <i
                            class="el-icon-arrow-right"
                            style="width: 8px; height: 8px"
                          ></i>
                        </div>
                      </div> -->

                      <div class="bottom-list1" @click="passwordStop">
                        <div class="left">修改密码</div>
                        <div class="right">
                          <i
                            class="el-icon-arrow-right"
                            style="width: 8px; height: 8px"
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div class="dropdown-div-block">
                      <div class="dropdown-div-block-write" @click="writeBtn">
                        取消
                      </div>
                      <div class="dropdown-div-block-quit" @click="quitBtn">
                        退出
                      </div>
                    </div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </header>

        <!-- 标签栏 -->
        <div class="div-tags">
          <ul class="ul-c">
            <li
              v-for="(item, index) in tagsArry"
              :key="index"
              :class="{ active: isActive(item.name) }"
              @click="activeTag(index)"
            >
              <a class="li-a">
                {{ item.text }}
              </a>
              <Icon size="16" @click="closeTag(index)" type="md-close" />
            </li>
          </ul>
          <!-- 标签栏相关功能 -->
          <div class="div-icons">
            <div class="refresh-c" @click="reloadPage" title="返回当前主页">
              <i style="margin-left: 8px" class="el-icon-backclass"></i>
            </div>
            <div class="tag-options" title="关闭标签">
              <Dropdown trigger="click" @on-click="closeTags">
                <Icon type="ios-options" />
                <DropdownMenu slot="list">
                  <DropdownItem name="1">关闭其他标签</DropdownItem>
                  <DropdownItem name="2">关闭所有标签</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- 页面主体 -->
      <div class="main-content">
        <div class="view-c">
          <keep-alive :include="keepAliveData">
            <!-- 子页面 -->
            <router-view v-if="isShowRouter" />
          </keep-alive>
        </div>
      </div>
    </section>

    <!--弹出框-->
    <div class="apply">
      <el-dialog
        title=""
        :visible.sync="dialogVisible0Show"
        width="400px"
        :before-close="handleClose"
      >
        <div class="applyfor">
          <div class="applyfor-img">
            <img src="../assets/partner-c.png" alt="" />
          </div>
          <div class="applyfor-title">缴纳保证金成为合伙人</div>
          <div class="applyfor-text">最少缴纳保证金金额</div>
          <div class="applyfor-price">
            <span>¥</span>{{ WalletInfoObj.collabrate_recharge }}
          </div>

          <div class="applyfor-button" @click="surrenderStop">缴纳保证金</div>
          <div class="applyfor-span">
            成为合伙人后，解除合伙人协议，退还全部保证金
          </div>
        </div>
      </el-dialog>
    </div>

    <!--缴纳保证金-->
    <div class="surrender">
      <el-dialog
        title="缴纳保证金"
        :visible.sync="dialogVisible1Show"
        :before-close="handleClose1"
      >
        <div class="surrender-block" style="margin-top: 30px">
          <div class="block-list">
            <div class="list-left">
              <span style="color: #ff6a20">*</span>输入金额
            </div>

            <div class="list-right">
              <el-input
                v-model="inputPrice"
                :placeholder="returnTitle"
                @input="inputPrice = inputPrice.replace(/^(0+)|[^\d]+/g, '')"
              ></el-input>
            </div>
          </div>

          <div class="block-list">
            <div class="list-left">
              <span style="color: #ff6a20">*</span>选择通道
            </div>

            <div class="list-right1">
              <div
                class="right1-div"
                v-for="(item, index) in 4"
                :key="index"
                :style="{
                  border: index == surrenderIndex ? '1px solid #29D647' : '',
                }"
                @click="right1Stop(index)"
              >
                <img
                  :style="{
                    width: index == 0 ? '25px' : index == 1 ? '21px' : '26px',
                    height: index == 0 ? '25px' : index == 1 ? '21px' : '16px',
                  }"
                  :src="
                    index == 0
                      ? require('../assets/user/icon-2.png')
                      : index == 1
                      ? require('../assets/user/icon-3.png')
                      : require('../assets/user/icon-4.png')
                  "
                  alt=""
                />

                <div class="div-title">
                  {{
                    index == 0
                      ? '微信支付'
                      : index == 1
                      ? '支付宝'
                      : index == 2
                      ? '银行卡'
                      : '银联在线'
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="block-display">
            <div class="display-list">重置</div>

            <div
              class="display-list"
              :style="{ opacity: inputPrice == '' ? 0.4 : 1 }"
              @click="paymentStop"
            >
              支付
            </div>
          </div>

          <div class="block-span">
            我已经阅读并同意
            <a
              style="color: #29d647"
              href="https://api.xmw1688.cn/baozhengjin.html"
              target="_blank"
              >《缴纳保证金协议》</a
            >
          </div>
        </div>
      </el-dialog>
    </div>

    <!--支付-->
    <div class="payment">
      <el-dialog
        title="支付"
        :visible.sync="dialogVisible2Show"
        width="362px"
        :before-close="handleClose2"
      >
        <div class="payment-block">
          <div class="block-title">应付金额</div>
          <div class="block-price">
            <span style="font-size: 20px; margin-right: 5px">¥</span
            >{{ inputPrice }}
          </div>
          <div class="block-text">
            请使用<span>{{
              surrenderIndex == 0
                ? '微信'
                : surrenderIndex == 1
                ? '支付宝'
                : surrenderIndex == 2
                ? '银行卡'
                : ''
            }}</span
            >扫一扫
          </div>
          <div
            v-if="surrenderIndex == 1"
            id="qrCode"
            style="display: flex; justify-content: center; margin: 10px 0"
            ref="qrCodeUrl"
          ></div>

          <img v-else class="block-imgs" :src="aggregatepaymentImage" alt="" />

          <div class="block-text">扫描二维码支付</div>
        </div>
      </el-dialog>
    </div>

    <!--支付成功-->
    <div class="success">
      <el-dialog
        title=""
        :visible.sync="dialogVisible3Show"
        width="320px"
        :before-close="handleClose3"
      >
        <div class="success-block">
          <div class="block-img">
            <img src="../assets/success.png" alt="" />
          </div>
          <div class="block-title">缴纳成功</div>
          <div class="block-text">缴纳金额：¥{{ inputPrice }}</div>
          <div class="block-span">您已提交成为合伙人申请，请等待审核</div>
        </div>
      </el-dialog>
    </div>

    <!--修改密码-->
    <div class="userpass">
      <el-dialog
        title="修改密码"
        :visible.sync="dialogVisible4Show"
        width="540px"
        :before-close="handleClose4"
      >
        <div class="userpass-block">
          <div class="block-list" v-for="(item, index) in 4" :key="index">
            <div class="list-left">
              <span style="color: #ff6a20">*</span
              >{{
                index == 0
                  ? '登录账号'
                  : index == 1
                  ? '验证码'
                  : index == 2
                  ? '新密码'
                  : '确认密码'
              }}
            </div>

            <div class="list-right">
              <el-input
                v-if="index == 0"
                v-model="inputaccount"
                placeholder="请输入登录账号"
              >
              </el-input>

              <el-input
                v-if="index == 1"
                v-model="inputverification"
                placeholder="请输入验证码"
                @input="
                  inputverification = inputverification.replace(
                    /^(0+)|[^\d]+/g,
                    ''
                  )
                "
              >
                <el-button
                  slot="append"
                  class="second-button"
                  @click="verificationClick"
                >
                  {{ count == 0 ? '获取验证码' : count + '秒' }}
                </el-button>
              </el-input>

              <el-input
                v-if="index == 2"
                v-model="inputpassword"
                placeholder="请输入新密码"
                type="password"
                show-password
              >
              </el-input>

              <el-input
                v-if="index == 3"
                v-model="inputpasswords"
                placeholder="请确认新密码"
                type="password"
                show-password
              >
              </el-input>
            </div>
          </div>

          <div class="block-display">
            <div class="display-list">重置</div>

            <div
              class="display-list"
              :style="{
                opacity:
                  inputaccount != '' &&
                  inputverification != '' &&
                  inputpassword != '' &&
                  inputpasswords != ''
                    ? 1
                    : 0.4,
              }"
              @click="modificationStop"
            >
              确认
            </div>
          </div>

          <div class="block-span">
            我已经阅读并同意
            <a
              style="color: #29d647"
              href="https://api.xmw1688.cn/baozhengjin.html"
              target="_blank"
              >《缴纳保证金协议》</a
            >
          </div>
        </div>
      </el-dialog>
    </div>

    <!--解除-->
    <div class="relieve">
      <el-dialog
        title="解除合伙人协议"
        :visible.sync="dialogVisible5Show"
        width="540px"
        :before-close="handleClose5"
      >
        <div class="relieve-block">
          <div class="block-title">退还保证金金额</div>
          <div class="block-price">
            <span style="font-size: 20px; margin-right: 5px">¥</span
            >{{ inputPrice }}
          </div>
          <div class="block-list">
            <div class="list-left">
              <span style="color: #ff6a20">*</span>选择通道
            </div>

            <div class="list-right1">
              <div class="right1-div" v-for="(item, index) in 3" :key="index">
                <img
                  :style="{
                    width: index == 0 ? '25px' : index == 1 ? '21px' : '26px',
                    height: index == 0 ? '25px' : index == 1 ? '21px' : '16px',
                  }"
                  :src="
                    index == 0
                      ? require('../assets/user/icon-2.png')
                      : index == 1
                      ? require('../assets/user/icon-3.png')
                      : require('../assets/user/icon-4.png')
                  "
                  alt=""
                />

                <div class="div-title">
                  {{
                    index == 0 ? '微信支付' : index == 1 ? '支付宝' : '银行卡'
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="block-button" @click="refundAllMet">确认解除</div>
          <div class="block-span">
            我已经阅读并同意
            <a
              style="color: #29d647"
              href="https://api.xmw1688.cn/baozhengjin.html"
              target="_blank"
              >《缴纳保证金协议》</a
            >
          </div>
        </div>
      </el-dialog>
    </div>

    <!--退还-->
    <div class="surrender">
      <el-dialog
        title="退还保证金"
        :visible.sync="dialogVisible6Show"
        width="566px"
        :before-close="handleClose6"
      >
        <div class="surrender-block">
          <div class="block-title">
            最少缴纳保证金金额：¥{{ WalletInfoObj.collabrate_recharge }}
          </div>
          <div class="block-title">
            实际缴纳保证金金额：¥{{ WalletInfoObj.depositMoney }}
          </div>
          <div class="block-title">
            最多可退保证金金额：<span style="color: red"
              >¥{{ dialogVisible6Price }}</span
            >
          </div>

          <div class="block-lints"></div>

          <div class="block-list">
            <div class="list-left">
              <span style="color: #ff6a20">*</span>输入金额
            </div>

            <div class="list-right">
              <el-input
                v-model="inputPrice"
                :placeholder="returnTitle"
                @input="inputPrice = inputPrice.replace(/^(0+)|[^\d]+/g, '')"
              ></el-input>
            </div>
          </div>

          <div class="block-list">
            <div class="list-left">
              <span style="color: #ff6a20">*</span>选择通道
            </div>

            <div class="list-right1">
              <div class="right1-div" v-for="(item, index) in 3" :key="index">
                <img
                  :style="{
                    width: index == 0 ? '25px' : index == 1 ? '21px' : '26px',
                    height: index == 0 ? '25px' : index == 1 ? '21px' : '16px',
                  }"
                  :src="
                    index == 0
                      ? require('../assets/user/icon-2.png')
                      : index == 1
                      ? require('../assets/user/icon-3.png')
                      : require('../assets/user/icon-4.png')
                  "
                  alt=""
                />

                <div class="div-title">
                  {{
                    index == 0 ? '微信支付' : index == 1 ? '支付宝' : '银行卡'
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="block-display">
            <div class="display-list">重置</div>

            <div
              class="display-list"
              :style="{ opacity: inputPrice == '' ? 0.4 : 1 }"
              @click="refundMet"
            >
              退款
            </div>
          </div>

          <div class="block-span">
            我已经阅读并同意
            <a
              style="color: #29d647"
              href="https://api.xmw1688.cn/baozhengjin.html"
              target="_blank"
              >《缴纳保证金协议》</a
            >
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 我的银行卡 -->
    <div class="mybank">
      <el-dialog
        title="我的银行卡"
        :visible.sync="dialogVisible7Show"
        width="566px"
        :before-close="handleClose7"
      >
        <div class="mybank-block">
          <div class="block-first" v-if="dialogVisible7TotalNum > 0">
            <div
              class="first-list"
              v-for="(item, index) in dialogVisible7List"
              :key="index"
              @click="mybankStop(item, index)"
            >
              <div class="list-A">
                <img
                  v-if="dialogVisible7Index == index"
                  src="../assets/check-1.png"
                  alt=""
                />
                <img v-else src="../assets/check-0.png" alt="" />
              </div>
              <div class="list-B">
                <div class="list-B-icon">
                  <img :src="item.icon_url" alt="" />
                </div>
                <div class="list-B-title">{{ item.bank_name }}</div>
              </div>
              <div class="list-C">
                <span style="margin-right: 10px">{{
                  item.type == 0 ? '借记卡' : item.type == 4 ? '信用卡' : ''
                }}</span>
                ({{ item.bank_numbers }})
              </div>
            </div>
          </div>

          <div class="block-second">
            <el-empty
              description="暂无签约银行"
              v-if="dialogVisible7TotalNum == 0"
            ></el-empty>
            <div
              class="second-button"
              style="margin-top: 30px"
              @click="mybankMet"
            >
              点击添加银行卡
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 签约银行 -->
    <div class="mysign">
      <el-dialog
        title="签约银行卡"
        :visible.sync="dialogVisible8Show"
        width="566px"
        :before-close="handleClose8"
      >
        <div class="mysign-block">
          <div class="block-first">
            <div class="first-list">
              <div class="list-title">银行卡</div>
              <div class="list-input">
                <el-select
                  v-model="signbank"
                  placeholder="请选择银行卡"
                  @change="selectChange"
                >
                  <el-option
                    v-for="item in signOptions"
                    :key="item.bankName"
                    :value="item.bank_id"
                  >
                    <span
                      style="float: left; display: flex; align-items: center"
                    >
                      <img
                        style="width: 16px; height: 16px; margin-right: 10px"
                        :src="item.icon_url"
                        alt=""
                      />
                      {{ item.bankName }}</span
                    >
                    <span
                      style="float: right; color: #8492a6; font-size: 14px"
                      v-if="
                        item.supportCreditCard == 'Y' &&
                        item.supportDebitCard == 'Y'
                      "
                    >
                    </span>
                    <span
                      style="float: right; color: #8492a6; font-size: 12px"
                      v-else
                    >
                      {{
                        item.supportDebitCard == 'Y'
                          ? '仅支持借记卡'
                          : item.supportCreditCard == 'Y'
                          ? '仅支持信用卡'
                          : ''
                      }}
                    </span>
                  </el-option>
                </el-select>

                <div class="first-righ" v-if="secondIndex != -1">
                  {{
                    secondIndex == 0
                      ? '借记卡'
                      : secondIndex == 1
                      ? '信用卡'
                      : ''
                  }}
                </div>
              </div>
            </div>

            <div class="first-list" @click="bankAccountMet">
              <div class="list-title">银行账号</div>
              <div class="list-input">
                <el-input
                  v-model="signbankNum"
                  placeholder="请输入银行卡账号"
                  :readonly="signbank != '' ? false : true"
                ></el-input>
              </div>
            </div>

            <div class="first-list" v-if="secondIndex == 1">
              <div class="list-title">银行卡有效期</div>
              <div class="list-input">
                <el-date-picker
                  v-model="signbankyearNum"
                  type="month"
                  placeholder="选择月"
                  format="MM/yy"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="first-list" v-if="secondIndex == 1">
              <div class="list-title">银行卡安全码</div>
              <div class="list-input">
                <el-input
                  type="password"
                  v-model="signbanksecurity"
                  placeholder="请输入银行卡安全码"
                ></el-input>
              </div>
            </div>

            <div class="first-list" @click="bankAccountMet">
              <div class="list-title">预留电话</div>
              <div class="list-input">
                <el-input
                  v-model="signbankPhone"
                  placeholder="请输入银行卡预留电话"
                  :readonly="signbank != '' ? false : true"
                ></el-input>
              </div>
            </div>

            <div class="first-list" @click="bankAccountMet">
              <div class="list-title">验证码</div>
              <div class="list-input">
                <el-input
                  v-model="signbankCode"
                  placeholder="请输入验证码"
                  :readonly="signbank != '' ? false : true"
                ></el-input>
                <div class="input-div" @click="verificationDiv">
                  {{ count == 0 ? '获取验证码' : count + '秒' }}
                </div>
              </div>
            </div>

            <div class="first-text">请按要求填写信息，快速绑卡</div>
          </div>

          <div class="block-second">
            <div class="second-button">
              <div class="button-list0" @click="signreset">重 置</div>
              <div
                class="button-list1"
                :style="{
                  opacity:
                    secondIndex == 0 &&
                    signbankNum != '' &&
                    signbankPhone != '' &&
                    signbankCode != '' &&
                    signbank != ''
                      ? '1'
                      : secondIndex == 1 &&
                        signbankNum != '' &&
                        signbankPhone != '' &&
                        signbankCode != '' &&
                        signbank != '' &&
                        signbankyearNum != '' &&
                        signbanksecurity != ''
                      ? '1'
                      : '0.3',
                }"
                @click="countersignStop"
              >
                同意协议并签约
              </div>
            </div>

            <div class="second-title">
              点击按钮表示同意签署
              <a
                style="color: #29d647"
                href="https://api.xmw1688.cn/factor-payment.html"
                target="_blank"
                >《乡米网快捷支付服务协议》</a
              >
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 签约银行支付短信 -->
    <div class="mysignnote">
      <el-dialog
        title="支付"
        :visible.sync="dialogVisible9Show"
        width="366px"
        :before-close="handleClose9"
      >
        <div class="mysignnote-block">
          <div class="block-title">支付金额</div>
          <div class="block-price">
            <span style="margin-right: 5px">¥</span>{{ inputPrice }}
          </div>
          <div class="block-text">
            支付方式：{{ dialogVisible9Obj.bank_name }}（{{
              dialogVisible9Obj.bankNum
            }}）
          </div>
          <div class="block-lint"></div>

          <div class="block-title1">请输入短信验证码完成支付</div>

          <div class="block-button">
            <div
              class="button-div"
              v-for="(item, index) in codeLength"
              :key="index"
            >
              <input
                type="text"
                v-model="item.pinless"
                class="inputValue"
                @keyup="keyboard($event, index)"
                @keydown="expurgate(index)"
                @input="
                  item.pinless = item.pinless.replace(/^0+(\d)|[^\d]+/g, '')
                "
              />
            </div>
          </div>

          <div class="block-buttom" @click="codeLengthMet">支付</div>
        </div>
      </el-dialog>
    </div>

    <!--签约银行卡二次确认--->
    <div class="cardbank">
      <el-dialog
        title="添加银行卡"
        :visible.sync="dialogVisible10Show"
        width="366px"
        :before-close="handleClose10"
      >
        <div class="second">
          <div class="second-title">选择要添加的银行卡类型</div>

          <div
            class="second-list"
            v-for="(item, index) in 2"
            :key="index"
            :style="{ background: secondIndex == index ? '#11D653' : '' }"
            @click="secondIndexMet(index)"
          >
            <div class="list-icon">
              <img :src="supportObj.icon_url" alt="" />
            </div>
            <div
              class="list-title"
              :style="{ color: secondIndex == index ? '#fff' : '' }"
            >
              {{ supportObj.bankName }}
            </div>
            <div
              class="list-title"
              :style="{ color: secondIndex == index ? '#fff' : '' }"
            >
              {{ index == 0 ? '借记卡' : '信用卡' }}
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--保证金缴纳记录-->
    <div class="record">
      <el-dialog
        title="保证金缴纳记录"
        :visible.sync="dialogVisible11Show"
        :before-close="handleClose11"
      >
        <div class="record-block">
          <div class="block-header">
            <div class="header-left">
              当前缴纳总金额：<span style="color: #ff4133; font-weight: bold"
                >{{ WalletInfoObj.depositMoney }}元</span
              >
            </div>

            <div class="header-right">
              <div class="right-title">缴纳状态</div>

              <el-select
                v-model="selectorValue"
                placeholder="全部订单状态"
                clearable
                @change="rightChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="block-first">
            <div class="first-header">
              <div class="header" v-for="(item, index) in 6" :key="index">
                {{
                  index == 0
                    ? '缴纳时间'
                    : index == 1
                    ? '缴纳方式'
                    : index == 2
                    ? '缴纳金额'
                    : index == 3
                    ? '下次补贴时间'
                    : index == 4
                    ? '预计下次补贴金额'
                    : '操作'
                }}
              </div>
            </div>

            <div class="first-table">
              <div
                class="table-list"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div
                  class="table"
                  v-for="(info, num) in 6"
                  :key="num"
                  @click="returnStop(item, num)"
                  :style="{ color: num == 5 && item.status != 1 ? '#ccc' : '' }"
                >
                  {{
                    num == 0
                      ? item.createTime
                      : num == 1
                      ? item.payType == 0
                        ? '银行卡'
                        : item.payType == 1
                        ? '微信'
                        : item.payType == 2
                        ? '支付宝'
                        : item.payType == 5
                        ? '银盛微信'
                        : item.payType == 6
                        ? '银盛支付宝'
                        : item.payType == 7
                        ? '银盛网银'
                        : ''
                      : num == 2
                      ? item.money
                      : num == 3
                      ? item.status == 1
                        ? item.earnet_rate_moneys_date
                        : ''
                      : num == 4
                      ? item.status == 1
                        ? item.myInterestPrice
                        : ''
                      : item.status == 1
                      ? '申请退还'
                      : item.status == 5
                      ? '退款申请中'
                      : item.status == 6
                      ? '退款完成'
                      : ''
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="block-paging">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="prev, pager, next, sizes"
              :total="totalNum"
            ></el-pagination>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--单次退还成功-->
    <div class="warning">
      <el-dialog
        title=""
        :visible.sync="dialogVisible12Show"
        :before-close="handleClose12"
      >
        <div class="warning-block">
          <div class="block-img">
            <img src="../assets/warning.png" alt="" />
          </div>
          <div class="block-title">
            {{ listTitle }}
          </div>

          <div class="block-display">
            <div class="display-list" @click="handleClose12">取消</div>

            <div class="display-list" @click="presentStop">确定</div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--银联在线-->
    <div class="Internetbank">
      <el-dialog
        title="银联在线"
        :visible.sync="InternetbankDialogVisible"
        :before-close="InternetbankHandleClose"
      >
        <div class="Internetbank-block">
          <div class="block-tabs">
            <van-tabs
              v-model="InternetbankActive"
              @change="tabsChange"
              color="#11d653"
            >
              <van-tab title="个人网银"> </van-tab>
              <van-tab title="企业网银"></van-tab>
            </van-tabs>
          </div>

          <div class="block-table">
            <div
              class="first-list"
              v-for="(item, index) in InternetbankList"
              :key="index"
              @click="InternetbankMet(item)"
            >
              <div class="list-B">
                <div class="list-B-icon">
                  <img :src="item.icon_url" alt="" />
                </div>
                <div class="list-B-title">{{ item.bankName }}</div>
              </div>
              <div
                class="list-C"
                v-if="
                  item.supportCreditCard == 'Y' && item.supportDebitCard == 'Y'
                "
              ></div>
              <div class="list-C" v-else>
                <span style="margin-right: 10px">
                  {{
                    item.supportDebitCard == 'Y'
                      ? '仅支持借记卡'
                      : item.supportCreditCard == 'Y'
                      ? '仅支持信用卡'
                      : ''
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--银联在线二次确认--->
    <div class="cardbank">
      <el-dialog
        title=" 选择支付"
        :visible.sync="InternetbankDialogVisible1"
        width="366px"
        :before-close="InternetbankHandleClose1"
      >
        <div class="second">
          <div class="second-title">选择要支付的银行卡类型</div>

          <div
            class="second-list"
            v-for="(item, index) in 2"
            :key="index"
            :style="{ background: InternetbankIndex == index ? '#11D653' : '' }"
            @click="InternetbankIndexMet(index)"
          >
            <div class="list-icon">
              <img :src="InternetbankObj.icon_url" alt="" />
            </div>
            <div
              class="list-title"
              :style="{ color: InternetbankIndex == index ? '#fff' : '' }"
            >
              {{ InternetbankObj.bankName }}
            </div>
            <div
              class="list-title"
              :style="{ color: InternetbankIndex == index ? '#fff' : '' }"
            >
              {{ index == 0 ? '借记卡' : '信用卡' }}
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { resetTokenAndClearUser } from '../utils'
import { userQuit, postcode, postretrieve } from '../api/login'
import {
  getInformation,
  getWalletInfo,
  getCreate_imageByUrl,
  //-------
  get_value,
} from '../api/index'

import {
  getOrderNum,
  postEarnest_pay,
  getIsRecharge,
  postDepositMoneyWithraw,
  postDepositAllMoneyWithraw,
  getqueryBankListBank,
  getQuickBankBank,
  postsignProtocolBank,
  postsignConfirmBank,
  postprotocolPayConfirmBank,
} from '../api/pay'

import { getdepositMoney, postdepositMoneyWithrawById } from '../api/index'

import QRCode from 'qrcodejs2'

var showLoading = null

export default {
  name: 'index',
  data() {
    return {
      // 用于储存页面路径
      paths: {},
      // 当前显示页面
      currentPage: '',
      openMenus: [], // 要打开的菜单名字 name属性
      menuCache: [], // 缓存已经打开的菜单
      hasNewMsg: true, // 是否有新消息
      isShowRouter: true,
      msgNum: '10', // 新消息条数
      // 标签栏         标签标题     路由名称
      // 数据格式 {text: '首页', name: 'home'}
      // 用于缓存打开的路由 在标签栏上展示
      tagsArry: [],
      arrowUp: false, // 用户详情向上箭头
      arrowDown: true, // 用户详情向下箭头
      isShowAsideTitle: true, // 是否展示侧边栏内容
      main: null, // 页面主要内容区域
      asideClassName: 'aside-big', // 控制侧边栏宽度变化
      asideArrowIcons: [], // 缓存侧边栏箭头图标 收缩时用
      // 面包屑
      crumbs: '主页',
      // 用户登录后获取对应的对象
      indexObj: {},
      // 主页路由名称
      home: 'home',
      WalletInfoObj: {},
      //-----------弹出框
      dialogVisible0Show: false,
      dialogVisible1Show: false,
      surrenderIndex: 0,
      dialogVisible2Show: false,
      dialogVisible3Show: false,

      //--------------支付逻辑
      aggregatepaymentOrder: '',
      returnTitle: '',
      inputPrice: '',
      sourceIndex: 0,
      //--------修改密码
      dialogVisible4Show: false,
      inputpasswords: '',
      inputpassword: '',
      inputverification: '',
      inputaccount: '',
      //------
      count: 0,
      timer: null,
      //--------退还解除
      dialogVisible5Show: false,
      dialogVisible6Show: false,
      dialogVisible6Price: '',
      //----------------
      dialogVisible7Show: false,
      dialogVisible7Index: 0,
      dialogVisible7List: [],
      dialogVisible7TotalNum: -1,
      dialogVisible7Page: 1,
      dialogVisible7Limit: 10,
      //----------
      dialogVisible8Show: false,
      signacontractObj: {},
      signOptions: [],
      supportObj: {},
      //-----------------
      dialogVisible9Obj: {},
      dialogVisible9Show: false,
      codeLength: [
        { pinless: '' },
        { pinless: '' },
        { pinless: '' },
        { pinless: '' },
        { pinless: '' },
        { pinless: '' },
      ],
      //------
      protocolObj: {},
      secondIndex: -1, // 0 借记卡  1 信用卡
      signbank: '',
      signbankNum: '',
      signbankPhone: '',
      signbankCode: '',
      signbankyearNum: '',
      signbanksecurity: '',
      //-------
      dialogVisible10Show: false,
      //----------------------------
      dialogVisible11Show: false,
      dialogVisible12Show: false,
      page: 1,
      limit: 10,
      tableData: [],
      totalNum: 0,
      dialogVisible11Obj: {},
      listTitle: '',
      // 全部订单状态选择对应值
      selectorValue: '1',
      // 全部订单状态
      options: [
        {
          value: '1',
          label: '正常',
        },
        {
          value: '5',
          label: '退款申请中',
        },
        {
          value: '6',
          label: '退款完成',
        },
      ],
      //---------判断是缴纳还是退款
      paymentShow: -1,
      aggregatepaymentImage: '',
      //----------------
      InternetbankDialogVisible: false,
      InternetbankActive: 0,
      InternetbankIndex: 0,
      InternetbankList: [],
      InternetbankDialogVisible1: false,
      InternetbankObj: {},
      //------------
    }
  },
  mounted() {
    // 第一个标签
    const name = this.$route.name
    this.currentPage = name

    this.tagsArry.push({
      text: this.nameToTitle[name],
      name,
    })

    // 根据路由打开对应的菜单栏
    this.openMenus = this.getMenus(name)
    this.$nextTick(() => {
      this.$refs.asideMenu.updateOpened()
    })

    // 设置用户信息
    this.main = document.querySelector('.sec-right')
    this.indexObj = JSON.parse(localStorage.getItem('userInfo'))
    this.asideArrowIcons = document.querySelectorAll(
      'aside .ivu-icon-ios-arrow-down'
    )

    // 监听窗口大小 自动收缩侧边栏
    this.monitorWindowSize()
    this.$store.commit('isReadBtn')
  },
  created() {
    this.getWalletInfoMet()
    this.getqueryBankListMet()

    //--
    // this.getqueryBankListBankMet()
  },
  watch: {
    $route(to) {
      const name = to.name
      this.currentPage = name

      this.homemessage()
      this.getqueryBankListMet()
      this.getWalletInfoMet()

      if (name == 'error') {
        this.crumbs = '404'
        return
      }

      if (!this.keepAliveData.includes(name)) {
        // 如果标签超过6个 则将第一个标签删除
        if (this.tagsArry.length == 6) {
          this.tagsArry.shift()
        }
        this.tagsArry.push({ name, text: this.nameToTitle[name] })
      }

      setTimeout(() => {
        this.crumbs = this.paths[name]
      }, 0)
    },
  },
  computed: {
    // 菜单栏
    menuItems() {
      let Obj = JSON.parse(localStorage.getItem('walletInfo'))
      if (Obj.collaborateStatus == 1) {
        return this.$store.state.menuItems
      } else {
        return this.$store.state.menuItemss
      }
    },
    // 需要缓存的路由
    keepAliveData() {
      return this.tagsArry.map((item) => item.name)
    },
    // 由于iView的导航菜单比较坑 只能设定一个name参数
    // 所以需要在这定义组件名称和标签栏标题的映射表 有多少个页面就有多少个映射条数
    nameToTitle() {
      const obj = {}
      this.menuItems.forEach((e) => {
        this.processNameToTitle(obj, e)
      })

      return obj
    },
  },
  methods: {
    //--------------------------获取数据
    getWalletInfoMet() {
      let params = {}

      getWalletInfo(params).then((res) => {
        if (res.data.code == 200) {
          this.WalletInfoObj = res.data.content
          localStorage.setItem('walletInfo', JSON.stringify(res.data.content))

          let num =
            Number(this.WalletInfoObj.depositMoney) -
            Number(this.WalletInfoObj.collabrate_recharge)

          if (this.sourceIndex == 0) {
            this.returnTitle = `最低缴纳保证${this.WalletInfoObj.collabrate_recharge}元`
          } else if (this.sourceIndex == 1) {
            this.returnTitle = '最大可提现保证金：' + num
            this.inputPrice = num
            this.dialogVisible6Price = num
          } else {
            this.inputPrice = this.WalletInfoObj.depositMoney
          }
        } else {
          this.$message(res.data.msg)
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          this.$router.replace('/login')
        }
      })
    },

    getMenus(name) {
      let menus
      const tagTitle = this.nameToTitle[name]
      for (let i = 0, l = this.menuItems.length; i < l; i++) {
        const item = this.menuItems[i]
        menus = []
        menus[0] = i
        if (item.text == tagTitle) {
          return menus
        }

        if (item.children) {
          for (let j = 0, ll = item.children.length; j < ll; j++) {
            const child = item.children[j]
            menus[1] = i + '-' + j
            menus.length = 2
            if (child.text == tagTitle) {
              return menus
            }

            if (child.children) {
              for (let k = 0, lll = child.children.length; k < lll; k++) {
                const grandson = child.children[k]
                menus[2] = i + '-' + j + '-' + k
                if (grandson.text == tagTitle) {
                  return menus
                }
              }
            }
          }
        }
      }
    },
    // 获取消息通知提示
    homemessage() {
      let params = {}
      getInformation(params).then((res) => {
        if (res.data.code == 200) {
          this.messageArray = res.data.content.informationList.slice(0, 4)
          localStorage.setItem('isReadIndex', res.data.content.isRead)
          this.$store.commit('isReadBtn', res.data.content.isRead)
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },

    monitorWindowSize() {
      let w = document.documentElement.clientWidth || document.body.clientWidth
      if (w < 1300) {
        this.shrinkAside()
      }

      window.onresize = () => {
        // 可视窗口宽度太小 自动收缩侧边栏
        if (
          w < 1300 &&
          this.isShowAsideTitle &&
          w >
            (document.documentElement.clientWidth || document.body.clientWidth)
        ) {
          this.shrinkAside()
        }

        w = document.documentElement.clientWidth || document.body.clientWidth
      }
    },

    // 判断当前标签页是否激活状态
    isActive(name) {
      return this.$route.name === name
    },
    // 跳转页面 路由名称和参数
    gotoPage(name, params) {
      this.currentPage = name
      this.crumbs = this.paths[name]
      this.$router.push({ name, params })

      if (!this.keepAliveData.includes(name)) {
        // 如果标签超过6个 则将第一个标签删除
        if (this.tagsArry.length == 6) {
          this.tagsArry.shift()
        }
        this.tagsArry.push({ name, text: this.nameToTitle[name] })
      }
    },
    // 选择菜单回调函数
    selectMenuCallback(name) {
      if (name.includes('external-link')) return
      this.gotoPage(name)
      this.expandAside()
      setTimeout(() => {
        this.isShowAsideTitle = true
      }, 200)
    },
    // 控制用户三角箭头显示状态
    showArrow(flag) {
      this.arrowUp = flag
      this.arrowDown = !flag
    },
    // 判断
    isShrinkAside() {
      if (this.isShowAsideTitle) {
        this.shrinkAside()
      } else {
        this.expandAside()
      }
    },
    // 收缩
    shrinkAside() {
      for (let i = 0, len = this.asideArrowIcons.length; i < len; i++) {
        this.asideArrowIcons[i].style.display = 'none'
      }

      this.isShowAsideTitle = false
      this.openMenus = []
      this.$nextTick(() => {
        if (this.$refs.asideMenu) {
          this.$refs.asideMenu.updateOpened()
        }
      })

      setTimeout(() => {
        this.asideClassName = ''
        this.main.style.marginLeft = '90px'
      }, 0)
    },
    // 展开
    expandAside() {
      setTimeout(() => {
        this.isShowAsideTitle = true
        for (let i = 0, len = this.asideArrowIcons.length; i < len; i++) {
          this.asideArrowIcons[i].style.display = 'block'
        }

        this.openMenus = this.menuCache
        if (this.$refs.asideMenu) {
          this.$refs.asideMenu.updateOpened()
        }
      }, 200)
      this.asideClassName = 'aside-big'
      this.main.style.marginLeft = ''
    },
    // 刷新当前标签页
    reloadPage() {
      let name = this.$route.name
      let index = this.keepAliveData.indexOf(name)
      this.$nextTick(() => {
        if (this.tagsArry.length) {
          this.isShowRouter = false
          this.tagsArry.splice(index, 1)
          this.$nextTick(() => {
            this.tagsArry.splice(index, 0, {
              name,
              text: this.nameToTitle[name],
            })
            this.gotoPage(name)
            this.isShowRouter = true
          })
        } else {
          this.isShowRouter = false
          this.$nextTick(() => {
            this.tagsArry.push({ name, text: this.nameToTitle[name] })
            this.gotoPage(name)
            this.isShowRouter = true
          })
        }
      })
    },
    // 关闭单个标签
    closeTag(i) {
      let name = this.tagsArry[i].name
      this.tagsArry.splice(i, 1)
      window.event.stopPropagation()
      // 如果关闭的是当前标签 则激活前一个标签
      // 如果关闭的是第一个标签 则激活后一个标签
      if (this.tagsArry.length) {
        if (this.isActive(name)) {
          if (i != 0) {
            this.gotoPage(this.tagsArry[i - 1].name)
          } else {
            this.gotoPage(this.tagsArry[i].name)
          }
        }
      } else if (name != this.home) {
        // 如果没有标签则跳往首页
        this.gotoPage(this.home)
      } else {
        this.reloadPage()
      }
    },
    // 根据路由名称关闭页面
    closeName(name) {
      for (let i = 0, len = this.tagsArry.length; i < len; i++) {
        if (this.tagsArry[i].name == name) {
          this.closeTag(i)
          break
        }
      }
    },
    // 批量关闭标签
    closeTags(flag) {
      if (flag == 1) {
        // 关闭其他标签
        this.tagsArry = []
        this.gotoPage(this.$route.name)
      } else {
        // 关闭所有标签
        this.tagsArry = []
        this.gotoPage(this.home)
        this.reloadPage()
      }
    },
    // 激活标签
    activeTag(i) {
      this.gotoPage(this.tagsArry[i].name)
    },
    // 消息通知
    info() {
      this.$router.push({
        path: 'information',
      })
    },
    // 菜单栏改变事件
    menuChange(data) {
      this.menuCache = data
    },
    processNameToTitle(obj, data, text) {
      if (data.name) {
        obj[data.name] = data.text
        this.paths[data.name] = text ? `${text} / ${data.text}` : data.text
      }
      if (data.children) {
        data.children.forEach((e) => {
          this.processNameToTitle(
            obj,
            e,
            text ? `${text} / ${data.text}` : data.text
          )
        })
      }
    },

    // 用户点击对应下拉菜单
    handleClick() {
      console.log('点击触发')
    },

    // 用户点击注销按钮
    writeBtn() {},
    // 用户点击退出按钮
    quitBtn() {
      let params = {}
      userQuit(params).then((res) => {})
      resetTokenAndClearUser()
      this.$router.push({ name: 'login' })
    },
    // 用户点击logo进行返回对应首页
    logoBtn() {
      this.$router.push({ name: 'home' })
    },

    //-----------------申请合伙人缴纳保证金
    partnerStop() {
      // 第一步判断是否实名认证
      if (this.WalletInfoObj.is_authentication == 1) {
        if (this.WalletInfoObj.collaborateStatus == -2) {
          this.$message.success(
            '欢迎您加入乡米网合伙人，公司将尽快查看审核，请您耐心等待～'
          )
          return
        }

        // 第二步 判断是否是合伙人
        let isNum = this.WalletInfoObj.collaborateStatus == 1 ? 0 : 1
        // 第三步 如果用户不是合伙人
        this.dialogVisible0Show = true
      } else {
        if (
          this.WalletInfoObj.authenticationStatus == null ||
          this.WalletInfoObj.authenticationStatus == '' ||
          this.WalletInfoObj.authenticationStatus == 2
        ) {
          this.$message('您尚未完成实名认证，请先在APP上完成实名认证。')
        } else {
          this.$message('实名认证审核中，请稍后…')
        }
      }
    },
    handleClose() {
      this.dialogVisible0Show = false
    },
    // 缴纳保证金
    surrenderStop() {
      this.sourceIndex = 0
      this.dialogVisible1Show = false
      this.dialogVisible1Show = true
    },
    handleClose1() {
      this.dialogVisible1Show = false
    },
    right1Stop(index) {
      this.surrenderIndex = index
    },
    handleClose2() {
      this.dialogVisible2Show = false
    },
    handleClose3() {
      this.dialogVisible3Show = false
      this.dialogVisible0Show = false
      this.dialogVisible1Show = false
      this.dialogVisible2Show = false
      this.dialogVisible12Show = false
      this.getWalletInfoMet()
    },

    //---------支付
    paymentStop() {
      if (this.inputPrice == '') {
        return
      }

      this.listTitle =
        '保证金存缴时间少于3个月，退款保证金时将扣除5%的手续费，是否同意？'
      this.paymentShow = 0
      this.dialogVisible12Show = true

      // 如果二维码对象已存在，
    },

    //------------------支付逻辑
    generateOrderMet() {
      let data = {
        key: 'is_reple',
      }

      get_value(data).then((res) => {
        if (res.data.code == 200) {
          if (res.data.content.value == 1) {
            let params = {
              type: 3,
            }

            getOrderNum(params).then((res) => {
              this.aggregatepaymentOrder = res.data.content

              if (this.surrenderIndex == 0) {
                this.weixinclick()
              } else if (this.surrenderIndex == 1) {
                this.zhifubaoclick()
              } else {
                this.$toast.loading({
                  mask: true,
                  message: '短信验证中',
                  duration: 2000,
                })

                this.Internetbankclick()
                setTimeout(() => {
                  this.dialogVisible9Show = true
                }, 2000)
              }
            })
          } else {
            this.$message(
              '系统正在升级，充值通道维护中，提现和提取保证金不受影响'
            )
          }
        }
      })

      console.log('isShow', this.keyisShow)
    },
    //微信支付点击事件
    weixinclick() {
      console.log('点击微信支付')
      this.wxClick()
    },

    //-------微信支付
    wxClick() {
      let params = {
        url: `https://api.xmw1688.cn/pay/#/?userId=${this.WalletInfoObj.id}&totalFee=${this.inputPrice}&orderNumber=${this.aggregatepaymentOrder}&token=${this.indexObj.accessToken}&differentiate=2`,
      }

      getCreate_imageByUrl(params).then((res) => {
        console.log('微信触发', res.data)

        this.aggregatepaymentImage = res.data
        this.dialogVisible2Show = true

        let _this = this
        _this.getOrderstate()
      })
    },

    //支付宝支付
    zhifubaoclick() {
      let params = {
        total_fee: this.inputPrice,
        orderNum: this.aggregatepaymentOrder,
        platform: 'Yinsheng',
        way: 'scan',
        channel: 'alipay',
      }
      postEarnest_pay(params).then((res) => {
        if (res.data.code == 200) {
          console.log(
            '----------------------------------------',
            res.data.content.qrCode
          )
          // this.$nextTick(() => {
          //   this.aggregatepaymentImage = res.data.content.qrCodeImage
          // })

          this.dialogVisible2Show = true

          var element = document.getElementById('qrCode')

          if (element) {
            element.innerHTML = ''
          } else {
          }

          let long = document.documentElement.clientWidth * (50 / 375)
          this.long = long

          //wx二维码显示
          this.$nextTick(() => {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
              text: res.data.content.qrCode,
              width: long,
              height: long,
              colorDark: '#333333', //二维码颜色
              colorLight: '#ffffff', //二维码背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            })
          })

          let _this = this
          _this.getOrderstate()
        } else {
          this.$message(res.data.msg)
        }
      })
    },

    // 银行卡支付
    Internetbankclick() {
      let params = {
        total_fee: this.inputPrice,
        orderNum: this.aggregatepaymentOrder,
        protocolNo: this.dialogVisible9Obj.protocolNo,
        platform: 'Yinsheng',
        way: 'quick',
        channel: '',
      }
      postEarnest_pay(params).then((res) => {
        if (res.data.code == 200) {
          this.protocolObj = res.data.content.bizResponseJson
        }
      })
    },

    getOrderstate(msga) {
      //判断支付是否成功，监听微信支付是否成功
      let _this = this
      let num = 0
      _this.timers = setInterval(() => {
        num++
        let params = {
          orderNum: this.aggregatepaymentOrder, //订单号
        }
        getIsRecharge(params).then((res) => {
          if (res.data.code == 201) {
            // document.body.style.overflow = "";
            // document.addEventListener("touchmove", { passive: true });
            // clearInterval(this.timers);
            // this.ispay = false;
            if (res.data.msg == '未支付') {
              return
            }
          } else {
            this.dialogVisible3Show = true

            clearInterval(this.timers)
          }
        })
        if (num == 500) {
          ;(this.ispay = false),
            //别忘了清除定时器
            clearInterval(this.timers)
        }
      }, 1500)
    },

    //-------修改密码
    passwordStop() {
      this.dialogVisible4Show = true
    },
    handleClose4() {
      this.dialogVisible4Show = false
    },
    verificationClick() {
      if (this.count != 0) {
        return
      }

      if (this.inputaccount == '') {
        this.$message('请先输入手机号！')
        return
      }

      //倒计时时间
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)

        this.postcodeMet()
      }
    },
    postcodeMet() {
      let params = {
        phone: this.inputaccount,
      }
      postcode(params).then((res) => {})
    },

    // 修改密码
    modificationStop() {
      if (this.inputpassword != this.inputpasswords) {
        this.$message('二次确认密码错误！')
        return
      }
      if (this.inputaccount != '' || this.inputpassword != '') {
        let params = {
          phone: this.inputaccount,
          code: this.inputverification,
          newPassword: this.inputpassword,
        }

        postretrieve(params).then((res) => {
          if (res.data.code == 200) {
            this.$message.success('修改成功！')

            setTimeout(() => {
              // // 退出登陆 清除用户资料
              localStorage.clear()
              // 重设路由
              this.$router.replace('/login')
            }, 1000)
          } else {
            this.$message(res.data.msg)
          }
        })
      }
    },

    //------------
    relieveStop() {
      this.sourceIndex = 2
      this.getWalletInfoMet()
      this.dialogVisible5Show = true
    },
    handleClose5() {
      this.dialogVisible5Show = false
    },
    refundAllMet() {
      let params = {}
      postDepositAllMoneyWithraw(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('全部退款成功！')

          setTimeout(() => {
            this.$router.go(0)
          }, 500)
        } else {
          this.$message(res.data.msg)
        }
      })
    },
    reimburseStop() {
      // 单独退款

      this.sourceIndex = 1
      this.getWalletInfoMet()

      this.dialogVisible6Show = true
    },
    handleClose6() {
      this.dialogVisible6Show = false
    },
    refundMet() {
      let num =
        Number(this.WalletInfoObj.depositMoney) - Number(this.inputPrice)

      if (Number(num) < Number(this.WalletInfoObj.collabrate_recharge)) {
        this.$message(
          `当前是部分退款保证金通道，账户保证金余额不能小于${this.WalletInfoObj.collabrate_recharge}元，请重新输入退款金额。`
        )
        return
      }

      let params = {
        total_fee: this.inputPrice,
      }

      postDepositMoneyWithraw(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('退款成功！')
          this.dialogVisible6Show = false
          this.getWalletInfoMet()
        } else {
          this.$message(res.data.msg)
        }
      })
    },
    //--------------
    getQuickBankBankMet() {
      let params = {
        pageSize: this.dialogVisible7Limit, // 页码
        page: this.dialogVisible7Page, // 页数
      }

      getQuickBankBank(params).then((res) => {
        if (res.data.code == 200) {
          res.data.content.list.forEach((item, index) => {
            item.bank_numbers = item.bank_number.slice(-4)
          })

          // 总共条数
          this.dialogVisible7TotalNum = Number(res.data.content.totalNum)
          this.dialogVisible7List = res.data.content.list
          console.log('触发', this.dialogVisible7List)

          this.dialogVisible7Show = true
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          this.$router.replace('/login')
        }
      })
    },

    handleClose7() {
      this.dialogVisible7Show = false
    },
    mybankStop(item, index) {
      this.dialogVisible9Obj = item
      this.dialogVisible9Obj.bankNum = item.bank_number.slice(-4)
      this.dialogVisible7Index = index

      this.generateOrderMet()
    },
    mybankMet() {
      this.dialogVisible7Show = false
      this.dialogVisible1Show = false
      this.dialogVisible8Show = true
    },
    //---------
    handleClose8() {
      this.dialogVisible8Show = false
    },
    //----------------------------------
    getqueryBankListMet() {
      let params = {}

      getqueryBankListBank(params).then((res) => {
        if (res.data.code == 200) {
          this.signOptions = res.data.content.bizResponseJson.bankList
        } else {
          this.$message(res.data.msg)
        }
      })
    },
    selectChange(e) {
      this.signOptions.forEach((item, index) => {
        if (e == item.bank_id) {
          //------------
          // 清空
          this.signbankNum = ''
          this.signbankPhone = ''
          this.signbankCode = ''
          this.signbankyearNum = ''
          this.signbanksecurity = ''

          if (item.supportCreditCard == 'Y' && item.supportDebitCard == 'Y') {
            this.signbank = item.bankName
            this.supportObj = item
            this.dialogVisible10Show = true
          } else if (
            item.supportCreditCard == 'Y' ||
            item.supportDebitCard == 'Y'
          ) {
            if (item.supportDebitCard == 'Y') {
              this.secondIndex = 0
            } else {
              this.secondIndex = 1
            }

            this.signbank = item.bankName
            this.supportObj = item
          } else {
            this.secondIndex = -1
            this.signbank = ''
            this.$message.error('参数错误，请检查填写的银行卡信息')
            return
          }
        }
      })
    },
    bankAccountMet() {
      if (this.signbank == '') {
        this.$message.error('请先勾选对应银行卡！')
      }
    },
    verificationDiv() {
      if (this.signbank == '') {
        this.$message.error('请先勾选对应银行卡！')
        return
      } else if (this.signbankNum == '') {
        this.$message.error('请填写银行账号！')
        return
      } else if (this.signbankPhone == '') {
        this.$message.error('请填写银行卡预留手机号码！')
        return
      }

      if (this.secondIndex == 1) {
        if (this.signbankyearNum == '') {
          this.$message.error('请填写银行卡有效期！')
          return
        } else if (this.signbanksecurity == '') {
          this.$message.error('请填写银行卡安全码！')
          return
        }
      }

      //倒计时时间
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }

      // 银行卡
      this.unificationMet()
    },
    unificationMet() {
      let params = {}

      if (this.secondIndex == 0) {
        params = {
          accountNo: this.signbankNum,
          phone: this.signbankPhone,
        }
      } else {
        params = {
          accountNo: this.signbankNum,
          phone: this.signbankPhone,
          validTimeEnd: this.signbankyearNum,
          cvv: this.signbanksecurity,
        }
      }

      postsignProtocolBank(params).then((res) => {
        if (res.data.code == 200) {
          this.signacontractObj = res.data.content.bizResponseJson
        } else {
          this.$message(res.data.msg)
        }
      })
    },
    //------------
    signreset() {
      this.signbankNum = ''
      this.signbankPhone = ''
      this.signbankCode = ''
      this.signbank = ''
      this.signbankyearNum = ''
      this.signbanksecurity = ''

      this.count = 0
      this.timer = null
    },
    //  确认签约
    countersignStop() {
      console.log('触发', this.secondIndex)

      if (
        this.signbankNum == '' &&
        this.signbankPhone == '' &&
        this.signbankCode == '' &&
        this.signbank == ''
      ) {
        return
      }

      if (this.secondIndex == 1) {
        if (this.signbankyearNum == '' && this.signbanksecurity == '') {
          return
        }
      }

      let params = {
        type: this.secondIndex == 0 ? 0 : this.secondIndex == 1 ? 4 : '',
        accountNo: this.signbankNum,
        phone: this.signbankPhone,
        smsCode: this.signbankCode,
        bankName: this.signbank,
        bank_type_id: this.supportObj.bank_id,
        requestNo: this.signacontractObj.requestNo,
      }

      postsignConfirmBank(params).then((res) => {
        if (res.data.code == 200) {
          this.$message('签约成功！')

          setTimeout(() => {
            this.dialogVisible8Show = false
            this.getQuickBankBankMet()
          })
        } else {
          this.$message(res.data.msg)
        }
      })
    },

    //----------银行卡支付
    handleClose9() {
      this.dialogVisible9Show = false
    },
    mysignnoteMet() {
      if (this.signbankPhone == '') {
        this.$message('请先输入预留手机号！')
        return
      }

      //倒计时时间
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    // 键盘松开事件
    keyboard(e, index) {
      if (!/^\+?[0-9][0-9]*$/.test(e.key)) {
        return
      }

      let domNode = document.getElementsByClassName('inputValue'),
        currInput = domNode[index],
        nextInput = domNode[index + 1],
        lastInput = domNode[index - 1]
      if (e.keyCode != 8) {
        if (index < this.codeLength.length - 1) {
          nextInput.focus()
        } else {
          currInput.blur()
        }
      } else {
        if (index != 0) {
          lastInput.focus()
        }
      }
    },
    // 键盘按下触发
    expurgate(index) {
      this.codeLength[index].pinless = ''
    },

    codeLengthMet() {
      let array = []

      this.codeLength.forEach((item, index) => {
        array.push(item.pinless)
      })

      let str = array.join('')

      if (str == '') {
        this.$toast({
          message: '请先填写验证码！',
        })
        return
      }

      this.$toast.loading({
        mask: true,
        message: '正在支付',
        duration: 2000,
      })

      let params = {
        smsCode: str,
        paygateBizSn: this.protocolObj.paygateBizSn,
      }

      postprotocolPayConfirmBank(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('支付成功！')
          this.dialogVisible0Show = false
          this.dialogVisible1Show = false
          this.dialogVisible7Show = false
          this.dialogVisible8Show = false
          this.dialogVisible9Show = false
        } else {
          this.$message(res.data.msg)
        }
      })
    },

    //---------------------二次确认
    handleClose10() {
      this.dialogVisible10Show = false
    },
    secondIndexMet(index) {
      this.secondIndex = index
      this.dialogVisible10Show = false
    },

    //-----------------------
    recordStop() {
      this.selectorValue = '1'
      this.getdepositMoneyMet()
    },
    handleClose11() {
      this.dialogVisible11Show = false
    },
    returnStop(item, index) {
      if (index == 5 && item.status == 1) {
        this.dialogVisible11Obj = item

        // 设置当前日期往后三个月 // 获取时间戳
        let time = new Date(item.endTime)
        time.setMonth(time.getMonth() + 3)
        const trimester = time.getTime()
        console.log('审核通过后的三个月时间', trimester)

        // 当前日期的时间戳
        const timestamp = +new Date()
        console.log('当前时间戳', timestamp)

        if (this.totalNum == 1 && Number(timestamp) < Number(trimester)) {
          this.listTitle =
            '该笔保证金存缴时间少于3个月，退款保证金将扣除5%的手续费，3~7个工作日，将原路退款您的账户，退还该笔保证金后，您将无法达到最低保证金数额，您将无法享有合伙人权益'
        } else if (this.totalNum == 1) {
          this.listTitle =
            '退还该笔保证金后，您将无法达到最低保证金数额，您将无法享有合伙人权益 '
        } else if (Number(timestamp) < Number(trimester)) {
          this.listTitle =
            '该笔保证金存缴时间少于3个月，退款保证金将扣除5%的手续费，3~7个工作日，将原路退款您的账户'
        } else {
          this.listTitle = '保证金将在3~7个工作日原路退回您的账户，请注意查收'
        }
        this.paymentShow = 1

        this.dialogVisible12Show = true
      }
    },
    handleClose12() {
      this.dialogVisible12Show = false
    },

    //---------------获取保证金列表记录
    rightChange(e) {
      this.selectorValue = e
      this.getdepositMoneyMet()
    },
    getdepositMoneyMet() {
      let params = {
        pageSize: this.limit, // 页码
        status: this.selectorValue,
        page: this.page, // 页数
      }

      getdepositMoney(params).then((res) => {
        if (res.data.code == 200) {
          res.data.content.list.forEach((item, index) => {
            item.money = Number(item.money).toFixed(2)
            item.myInterestPrice = Number(item.myInterestPrice).toFixed(2)
          })

          this.tableData = res.data.content.list
          this.totalNum = Number(res.data.content.totalNum)

          this.dialogVisible11Show = true
        }
      })
    },
    presentStop() {
      if (this.paymentShow == 0) {
        if (this.surrenderIndex == 2) {
          let data = {
            key: 'is_reple',
          }

          get_value(data).then((res) => {
            if (res.data.code == 200) {
              if (res.data.content.value == 1) {
                this.getQuickBankBankMet()
              } else {
                this.$message(
                  '系统正在升级，充值通道维护中，提现和提取保证金不受影响'
                )
              }
            }
          })
        } else if (this.surrenderIndex == 3) {
          let data = {
            key: 'is_reple',
          }

          get_value(data).then((res) => {
            if (res.data.code == 200) {
              if (res.data.content.value == 1) {
                this.getqueryBankListBankMet()
              } else {
                this.$message(
                  '系统正在升级，充值通道维护中，提现和提取保证金不受影响'
                )
              }
            }
          })
        } else {
          this.generateOrderMet()
        }
      } else {
        this.postdepositMoneyWithrawByIdMet()
      }
    },
    postdepositMoneyWithrawByIdMet() {
      let params = {
        id: this.dialogVisible11Obj.id,
      }

      postdepositMoneyWithrawById(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('申请退款！')

          this.getdepositMoneyMet()
          this.dialogVisible12Show = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    //  查询当前页码多少条
    handleSizeChange(val) {
      this.limit = val
      this.getdepositMoneyMet()
    },
    //  查询当前页
    handleCurrentChange(val) {
      this.page = val
      this.getdepositMoneyMet()
    },
    //---------------------------------------------银联
    InternetbankHandleClose() {
      this.InternetbankDialogVisible = false
    },
    InternetbankHandleClose1() {
      this.InternetbankDialogVisible1 = false
    },
    tabsChange(e) {
      this.InternetbankActive = e

      showLoading = this.$toast.loading({
        mask: true,
        message: '加载中...',
      })

      this.getqueryBankListBankMet(0)
    },
    InternetbankIndexMet(index) {
      this.InternetbankIndex = index

      showLoading = this.$toast.loading({
        mask: true,
        loadingType: 'spinner',
        message: '银联在线支付中',
      })

      this.InternetbankDialogVisible1 = false
      //   this.InternetbankDialogVisible = false
      //   this.dialogVisible12Show = false
      //   this.dialogVisible1Show = false
      //   this.dialogVisible0Show = false

      this.UnionpaybankDemand()
    },
    InternetbankMet(item) {
      this.InternetbankObj = item

      if (item.supportCreditCard == 'Y' && item.supportDebitCard == 'Y') {
        this.InternetbankIndex = -1
        this.InternetbankDialogVisible1 = true
      } else if (
        item.supportCreditCard == 'Y' ||
        item.supportDebitCard == 'Y'
      ) {
        if (item.supportDebitCard == 'Y') {
          this.InternetbankIndex = 0
        } else {
          this.InternetbankIndex = 1
        }

        showLoading = this.$toast.loading({
          mask: true,
          loadingType: 'spinner',
          message: '银联在线支付中',
        })

        this.InternetbankDialogVisible1 = false
        // this.InternetbankDialogVisible = false
        // this.dialogVisible12Show = false
        // this.dialogVisible1Show = false
        // this.dialogVisible0Show = false

        if (this.InternetbankActive == 0) {
          this.UnionpaybankDemand()
        } else {
          this.enterpriseBankDemand()
        }
      } else {
        this.InternetbankIndex = -1
        this.$toast({
          message: '当前银行不支持该操作！',
        })
      }
    },
    //------------------
    getqueryBankListBankMet(index) {
      let params = {
        protocolType: this.InternetbankActive == 0 ? '03' : '04',
      }

      getqueryBankListBank(params).then((res) => {
        console.log('测试', res)

        console.log('触发', index)
        if (index == 0) {
          showLoading.clear()
        }

        if (res.data.code == 200) {
          let array = res.data.content.bizResponseJson.bankList.filter(
            (item) =>
              item.supportCreditCard == 'Y' || item.supportDebitCard == 'Y'
          )

          let list = array.filter((item) => item.bankName != 'null')

          //   let arrayList = list.filter((item) => item.bankName != 'null')

          this.InternetbankList = list

          this.InternetbankDialogVisible = true
        }
      })
    },
    //---------------------------------------
    //-------------用户进行支付 个人  h5
    UnionpaybankDemand() {
      let str =
        'https://partner.xmw1688.cn/#/home?inputPrice=' + this.inputPrice

      let params = {
        total_fee: this.inputPrice,
        platform: 'Yinsheng',
        way: 'bank',
        channel: this.InternetbankObj.code,
        //--------

        //----------------
        payMode: '01',
        bankAccountType:
          this.InternetbankIndex == 0
            ? '11'
            : this.InternetbankIndex == 1
            ? '12'
            : '',
        redirectUrl: str,
      }

      console.log('参数', params)

      postEarnest_pay(params).then((res) => {
        if (res.data.code == 200) {
          showLoading.clear()
          //   window.location.href = res.data.content.bizResponseJson.payUrl;
          window.open(res.data.content.bizResponseJson.payUrl, '_blank')
        } else {
          this.$toast(res.data.msg)
        }
      })
    },

    // 企业网银
    enterpriseBankDemand() {
      let str =
        'https://partner.xmw1688.cn/#/home?inputPrice=' + this.inputPrice

      let params = {
        total_fee: this.inputPrice,
        platform: 'Yinsheng',
        way: 'bank',
        channel: this.InternetbankObj.code,
        payMode: '01',
        bankAccountType:
          this.InternetbankIndex == 0
            ? '21'
            : this.InternetbankIndex == 1
            ? '22'
            : '',
        redirectUrl: str,
      }
      postEarnest_pay(params).then((res) => {
        if (res.data.code == 200) {
          showLoading.clear()
          window.open(res.data.content.bizResponseJson.payUrl, '_blank')

          //   window.location.href = res.data.content.bizResponseJson.payUrl;
        } else {
          this.$toast(res.data.msg)
        }
      })
    },

    // getqueryBankListBank
  },
}
</script>

<style lang="less" scoped>
.index-vue {
  height: 100%;
  color: #666;
}
/deep/ .ivu-menu-dark {
  background: #ffffff;
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-item {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-item:hover {
  background: rgba(41, 214, 71, 0.1);
  color: #29d647;
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active,
.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active:hover {
  background: #29d647;
}

/deep/
  .ivu-menu-dark.ivu-menu-vertical
  .ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: rgba(41, 214, 71, 0.1);
  color: #29d647;
}

/deep/
  .ivu-menu-dark.ivu-menu-vertical
  .ivu-menu-opened
  .ivu-menu-submenu-title {
  background: none !important;
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-item {
  color: #222222;
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title:hover {
  background: none !important;
}

/deep/
  .ivu-menu-dark.ivu-menu-vertical
  .ivu-menu-opened
  .ivu-menu-submenu-title {
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened {
  background: none !important;
}

/deep/
  .ivu-menu-dark.ivu-menu-vertical
  .ivu-menu-submenu
  .ivu-menu-item-active {
  background: rgba(41, 214, 71, 0.1) !important;
  color: #29d647;
}

/deep/ .el-dropdown-menu {
  padding: 0;
}

/deep/ .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item:hover {
  background: rgba(41, 214, 71, 0.1) !important;
}

/* 侧边栏 */
aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 90px;
  height: 100%;
  transition: width 0.3s;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
}
.logo-c {
  height: 108px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: ToppanBunkyuMidashiGothicStdN-ExtraBold,
    ToppanBunkyuMidashiGothicStdN;
  font-weight: 800;
  color: #29d647;

  .logo-c-img {
    width: 105px;
    height: 54px;
  }
}
.logo {
  width: 40px;
  margin-right: 10px;
}
.aside-big {
  width: 220px;
}
/* 主体页面 */
.sec-right {
  height: 100%;
  margin-left: 220px;
  transition: margin-left 0.3s;
  overflow: hidden;
  background: #f6f6f6;
}

/* 主体页面头部 */
header {
  height: 68px;
  border-bottom: none;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  padding-left: 10px;
  font-size: 14px;
}
header .ivu-icon {
  font-size: 24px;
}
.refresh-c {
  margin: 0 30px;
  cursor: pointer;
}
.h-right {
  display: flex;
  align-items: center;
}
.h-left {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.user-img-c img {
  width: 100%;
}

.partner-c {
  padding: 0 10px;
  box-sizing: border-box;
  height: 32px;
  background: #eafbed;
  border-radius: 5px;
  border: 1px solid #29d647;
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 19.78px;
    height: 19.78px;
    margin-right: 10px;
  }

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #29d647;
  cursor: pointer;
}

.notice-c {
  cursor: pointer;
  position: relative;
}
.newMsg {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff5722;
  right: 0;
  top: 0;
}
.user-img-c {
  width: 34px;
  height: 34px;
  background: #ddd;
  border-radius: 50%;
  margin: 0 10px 0 22px;
  overflow: hidden;
}
.tag-options {
  cursor: pointer;
  position: relative;
}
.div-tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  padding-left: 20px;
  padding-bottom: 10px;
  background: #fff;
}
.div-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  height: 34px;
  width: 160px;
  font-size: 18px;
}
/* 标签栏 */
.ul-c {
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px 0 0;
  overflow: hidden;
  width: calc(100% - 160px);
}
.ul-c li {
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  height: 34px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 5px 2px 3px;
  border: 1px solid #e6e6e6;
  width: calc(100% / 8);
  position: relative;
}
a {
  color: #666;
  transition: none;
}
.li-a {
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ul-c .ivu-icon {
  position: absolute;
  right: 16px;
}
.active {
  background: #409eff;
  border: 1px solid #409eff;
}
.active a {
  color: #fff;
}
.active .ivu-icon {
  color: #fff;
}
/* 主要内容区域 */
.main-content {
  height: calc(100% - 88px);
  overflow: hidden;
}
.view-c {
  position: relative;
  height: 100%;
  overflow: auto;
}
.pointer {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
}
.crumbs {
  margin-left: 10px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  cursor: pointer;
}
.menu-level-3 .ivu-icon {
  font-size: 18px;
}
.shrink {
  text-align: center;
}
.external {
  color: rgba(255, 255, 255, 0.7);
}
.external > i {
  margin-right: 6px;
}

/deep/ .el-dropdown-menu__item {
  padding: 0;
}

.dropdown-div {
  width: 200px;
  background: #ffffff;
  padding: 0 16px;
  box-sizing: border-box;

  .dropdown-div-top {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    .dropdown-div-top-img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .dropdown-div-top-name {
      margin-left: 8px;
      font-size: 18px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #444444;
    }
  }

  .dropdown-div-center1 {
    margin-top: 3px;
    display: flex;
    align-items: center;
    height: 20px;

    .center1-left {
      width: 50px;
      height: 20px;
    }

    .center1-right {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #29d647;
      background: #eafbed;
      border-radius: 2px;
      border: 1px solid #88e899;
      height: 20px;
      line-height: 20px;
      padding: 0 10px;
      box-sizing: border-box;
    }

    .center2-right {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #29d647;
    }

    .center-2 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #29d647;
      text-align: right;
    }
  }

  .dropdown-div-lint {
    margin: 11px 0;
    width: 100%;
    height: 1px;
    background: #ccc;
  }

  .dropdown-div-bottom {
    .bottom-list,
    .bottom-list1 {
      height: 40px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
      }

      .right {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .right-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ff573e;
        }
      }
    }

    .bottom-list:hover {
      .left {
        color: #29d647;
      }
    }

    .bottom-list1:hover {
      .left {
        color: #29d647;
      }
    }
  }

  .dropdown-div-block {
    display: flex;
    justify-content: center;
    margin-top: 21px;

    .dropdown-div-block-write {
      width: 62px;
      height: 28px;
      background: #eafbed;
      border-radius: 4px;
      border: 1px solid #29d647;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #29d647;
      text-align: center;
      line-height: 28px;
    }
    .dropdown-div-block-quit {
      width: 62px;
      height: 28px;
      background: #29d647;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      line-height: 28px;
      margin-left: 24px;
    }
  }
}

.apply {
  /deep/ .el-dialog__header {
    padding: 0;
  }

  .applyfor {
    .applyfor-img {
      width: 50.45px;
      height: 50.45px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .applyfor-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #11d653;
      margin: 11px 0 32px;
      text-align: center;
    }

    .applyfor-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      margin-bottom: 5px;
      text-align: center;
    }

    .applyfor-price {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 36px;
      color: #222222;
      text-align: center;
      display: flex;
      align-items: baseline;
      justify-content: center;

      span {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    .applyfor-button {
      width: 306px;
      height: 44px;
      background: #29d647;
      border-radius: 5px;
      margin: 0 auto;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
    }

    .applyfor-span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #ff5151;
      margin-top: 12px;
      text-align: center;
    }
  }
}

.surrender {
  /deep/ .el-dialog {
    width: 700px;
  }

  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  /deep/ .el-dialog__body {
    padding-top: 0;
  }

  .surrender-block {
    .block-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin-bottom: 10px;
    }

    .block-lints {
      width: 100%;
      height: 1px;
      margin: 22px 0;
      background: #ededed;
    }

    .block-span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
    }

    .block-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      height: 50px;

      .list-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
      }

      .list-right {
        margin-left: 10px;
        width: 86%;

        /deep/ .el-input__inner {
          height: 50px;
          line-height: 50px;
        }
      }

      .list-right1 {
        margin-left: 10px;
        width: 86%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right1-div {
          width: calc(100% / 4);
          height: 50px;
          border: 1px solid #eeeeee;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;

          .div-title {
            margin-left: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #757575;
          }
        }

        .right1-divs {
          border: 1px solid #29d647;
        }

        .right1-div:last-child {
          margin-right: 0;
        }
      }
    }

    .block-display {
      display: flex;
      margin-top: 38px;
      justify-content: flex-end;

      .display-list {
        width: 126px;
        height: 38px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 38px;
        margin-left: 20px;
        cursor: pointer;
      }

      .display-list:last-child {
        background: #29d647;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .block-span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #a3a3a3;
      text-align: right;
      margin-top: 16px;
    }
  }
}

.payment {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  /deep/ .el-dialog__body {
    padding-top: 0;
  }

  .payment-block {
    position: relative;
    text-align: center;
    .block-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      text-align: center;
    }

    .block-price {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 36px;
      color: #222222;
      text-align: center;
      margin-top: 5px;
    }

    .block-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      margin-top: 17px;
      text-align: center;

      span {
        color: #ff6a20;
        font-size: 20px;
        margin: 0 10px;
      }
    }

    .block-imgs {
      width: 200px;
      height: 200px;
      margin-top: 10px;
    }
  }
}

.success {
  /deep/ .el-dialog__header {
    padding: 0;
  }

  .success-block {
    .block-img {
      width: 50px;
      height: 50px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .block-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #29d647;
      margin-top: 8px;
      text-align: center;
    }

    .block-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      margin-top: 14px;
      text-align: center;
    }

    .block-span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      margin-top: 4px;
      text-align: center;
    }
  }
}

.userpass {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  .userpass-block {
    .block-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      height: 50px;

      .list-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        width: 15%;
      }

      .list-right {
        margin-left: 10px;
        width: 85%;

        /deep/ .el-input__inner {
          height: 50px;
          line-height: 50px;
        }

        /deep/ .el-input-group__append {
          background: #29d647;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
        }
      }

      .list-right1 {
        margin-left: 10px;
        width: 88%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right1-div {
          width: calc(100% / 3);
          height: 50px;
          border: 1px solid #eeeeee;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;

          .div-title {
            margin-left: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #757575;
          }
        }

        .right1-divs {
          border: 1px solid #29d647;
        }

        .right1-div:last-child {
          margin-right: 0;
        }
      }
    }

    .block-display {
      display: flex;
      margin-top: 38px;
      justify-content: flex-end;

      .display-list {
        width: 126px;
        height: 38px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 38px;
        margin-left: 20px;
        cursor: pointer;
      }

      .display-list:last-child {
        background: #29d647;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .block-span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #a3a3a3;
      text-align: right;
      margin-top: 16px;
    }
  }
}

.relieve {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  .relieve-block {
    .block-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      text-align: center;
    }

    .block-price {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 36px;
      color: #222222;
      text-align: center;
      margin-top: 5px;
    }

    .block-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      height: 50px;
      margin-top: 49px;

      .list-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        width: 15%;
      }

      .list-right {
        margin-left: 10px;
        width: 85%;

        /deep/ .el-input__inner {
          height: 50px;
          line-height: 50px;
        }

        /deep/ .el-input-group__append {
          background: #29d647;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
        }
      }

      .list-right1 {
        margin-left: 10px;
        width: 88%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right1-div {
          width: calc(100% / 3);
          height: 50px;
          border: 1px solid #eeeeee;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;

          .div-title {
            margin-left: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #757575;
          }
        }

        .right1-divs {
          border: 1px solid #29d647;
        }

        .right1-div:last-child {
          margin-right: 0;
        }
      }
    }

    .block-button {
      width: 306px;
      height: 44px;
      background: #29d647;
      border-radius: 5px;
      margin: 0 auto;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
    }

    .block-span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #a3a3a3;
      text-align: center;
      margin-top: 16px;
    }
  }
}

.mybank {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  .mybank-block {
    .block-first {
      .first-list {
        height: 60px;
        border-top: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        padding-left: 16px;
        cursor: pointer;

        .list-A {
          width: 22px;
          height: 22px;
          margin-right: 28px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .list-B {
          display: flex;
          align-items: center;
          margin-right: 59px;

          .list-B-icon {
            width: 28px;
            height: 28px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .list-B-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: #222222;
          }
        }
        .list-C {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #999999;

          span {
            color: #999999;
          }
        }
      }

      .first-list:last-child {
        border-bottom: 1px solid #f1f1f1;
      }
    }

    .block-second {
      /deep/ .el-empty {
        padding-top: 0;
      }

      .second-button {
        width: 218px;
        height: 38px;
        background: #29d647;
        border-radius: 5px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

.mysign {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  .mysign-block {
    .block-first {
      .first-list {
        margin-bottom: 10px;

        .list-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          margin-bottom: 5px;
        }
        .list-input {
          display: flex;
          align-items: center;
          position: relative;

          /deep/ .el-input__inner {
            height: 44px;
            line-height: 44px;
            font-size: 14px;
          }

          ::v-deep .el-input input::-webkit-input-placeholder {
            color: #c0c4cc;
            font-size: 14px;
          }

          ::v-deep .el-input input::-moz-input-placeholder {
            color: #c0c4cc;
            font-size: 14px;
          }

          ::v-deep .el-input input::-ms-input-placeholder {
            color: #c0c4cc;
            font-size: 14px;
          }

          /deep/ .el-input__icon {
            height: 44px;
            line-height: 44px;
          }

          /deep/ .el-select {
            width: 100%;
          }

          /deep/ .el-date-editor.el-input {
            width: 100%;
          }

          .input-div {
            width: 150px;
            height: 44px;
            background: #ffffff;
            border-radius: 5px;
            border: 1px solid #29d647;
            text-align: center;
            line-height: 44px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #29d647;
            margin-left: 10px;
            cursor: pointer;
          }

          .first-righ {
            position: absolute;
            right: 40px;
            z-index: 10;
            font-size: 14px;
            color: #999999;
          }
        }
      }

      .first-text {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ff7070;
        margin-top: 17px;
      }
    }

    .block-second {
      .second-button {
        display: flex;
        justify-content: flex-end;

        .button-list0 {
          width: 126px;
          height: 38px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 38px;
          text-align: center;
          cursor: pointer;
        }

        .button-list1 {
          width: 206px;
          height: 38px;
          background: #29d647;
          border-radius: 5px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          text-align: center;
          line-height: 38px;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .second-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #a3a3a3;
        text-align: right;
        margin-top: 10px;
      }
    }
  }
}

.mysignnote {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  /deep/ .el-dialog__body {
    padding-top: 10px;
  }

  .mysignnote-block {
    .block-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #555555;
      text-align: center;
    }
    .block-price {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 27px;
      color: #222222;
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin: 6px 0 9px;
    }

    .block-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      text-align: center;
    }

    .block-lint {
      width: 100%;
      height: 1px;
      background: #ececed;
      margin: 23px 0 20px;
    }
    .block-title1 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
    }

    .block-display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      .block-display-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
      }

      .block-display-right {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 13px;
        color: #11d653;
        width: 88px;
        height: 27px;
        border-radius: 22px;
        border: 1px solid #11d653;
        cursor: pointer;
        text-align: center;
        line-height: 27px;
      }
    }

    .block-button {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;

      .button-div {
        width: 39px;
        height: 39px;

        /deep/ input {
          width: 100%;
          height: 100%;
          text-align: center;
          background: #e4e4e4;
          border-radius: 3px;
          border: 0;
        }

        :focus-visible {
          outline: none;
        }
      }
    }

    .block-buttom {
      width: 206px;
      height: 38px;
      background: #29d647;
      border-radius: 5px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      margin-left: 12px;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 40px;
    }
  }
}

.cardbank {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  /deep/ .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .second {
    .second-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      text-align: left;
      margin-bottom: 20px;
    }

    .second-list {
      display: flex;
      align-items: center;
      padding: 0 10px 0 20px;
      box-sizing: border-box;
      background: #f7f7f7;
      height: 50px;
      margin-bottom: 10px;
      border-radius: 10px;
      cursor: pointer;

      .list-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .list-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #222222;
        margin-right: 20px;
      }
    }
  }
}

.record {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  /deep/ .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .record-block {
    .block-header {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
      }

      .header-right {
        display: flex;
        align-items: center;

        .right-title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-right: 10px;
        }

        /deep/ .el-input {
          width: 200px;
        }

        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          cursor: pointer;
        }

        /deep/ .el-input__icon {
          line-height: 32px;
        }
      }
    }

    .block-first {
      margin-top: 21px;
      .first-header {
        height: 54px;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        display: flex;

        .header {
          width: calc(100% / 4);
          height: 54px;
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #4c4c4c;
          line-height: 54px;
        }
      }

      .first-table {
        margin-top: 10px;
        height: 40vh;
        overflow: auto;
        padding-bottom: 10px;
        box-sizing: border-box;

        .table-list {
          display: flex;
          height: 71px;
          border-bottom: 1px solid #e8e8e8;

          .table {
            width: calc(100% / 4);
            height: 71px;
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #4c4c4c;
            line-height: 71px;
          }

          .table:last-child {
            color: #2aa4ff;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .table-list:first-child {
          border-top: 1px solid #e8e8e8;
        }
      }

      ::-webkit-scrollbar {
        width: 10px; /* 垂直滚动条的宽度 */
        height: 10px; /* 水平滚动条的高度 */
      }

      /* 滚动条轨道 */
      ::-webkit-scrollbar-track {
        background: #f1f1f1; /* 轨道颜色 */
      }

      /* 滚动条滑块 */
      ::-webkit-scrollbar-thumb {
        background: #888; /* 滑块颜色 */
      }

      /* 滑块hover效果 */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; /* 滑块hover颜色 */
      }
    }

    .block-paging {
      width: 100%;
      background: #ffffff;
      padding-right: 30px;

      /deep/ .el-pagination {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
      }

      /deep/ .el-pager li {
        width: 32px;
        height: 32px;
        padding: 0;
        line-height: 32px;
        margin-right: 8px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
      }

      /deep/ .el-pager li.active {
        background: #eafbed;
        border-radius: 4px;
        color: #29d647;
      }

      /deep/ .el-pager li:hover {
        color: #29d647;
      }

      /deep/ .el-pagination .btn-prev {
        width: 32px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        margin-right: 8px;
      }

      /deep/.el-pagination .btn-next {
        width: 32px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        margin-right: 8px;
      }

      /deep/ .el-pagination button:hover {
        color: #29d647;
      }
    }
  }
}

.warning {
  /deep/ .el-dialog {
    width: 400px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  .warning-block {
    .block-img {
      width: 56px;
      height: 56px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .block-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 30px;
      text-align: center;
    }

    .block-display {
      display: flex;
      margin-top: 20px;
      justify-content: center;

      .display-list {
        width: 88px;
        height: 38px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 38px;
        margin-left: 20px;
        cursor: pointer;
      }

      .display-list:last-child {
        background: #29d647;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}

.Internetbank {
  /deep/ .el-dialog__header {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  /deep/ .el-dialog {
    width: 600px;
  }

  /deep/ .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .Internetbank-block {
    .block-tabs {
    }

    .block-table {
      margin-top: 10px;
      overflow: auto;
      height: 450px;

      .first-list {
        height: 60px;
        border-top: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;
        justify-content: space-between;

        .list-A {
          width: 22px;
          height: 22px;
          margin-right: 28px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .list-B {
          display: flex;
          align-items: center;

          .list-B-icon {
            width: 28px;
            height: 28px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .list-B-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: #222222;
          }
        }
        .list-C {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #999999;

          span {
            color: #999999;
          }
        }
      }

      .first-list:last-child {
        border-bottom: 1px solid #f1f1f1;
      }
    }
  }
}
</style>
